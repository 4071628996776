<template>
  <!-- 用电明细：根据编号，日期列出每张表在每天的电表读数 -->
  <div id="app">
    <div class="container">
      <el-row :gutter="10">
        <!-- 日期选择 -->
        <el-col :span="4">
          <el-date-picker
            style="width: 100%"
            v-model="timePickerValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
          >
          </el-date-picker>
        </el-col>

        <!-- 查询按钮 -->
        <el-col :span="1.5">
          <el-button type="primary" @click="getTableData">查 询</el-button>
        </el-col>

        <!-- 导出excel表格 -->
        <el-col :span="3">
          <download-excel
            types="xls"
            :fields="electricExcelfields"
            :data="tableData"
          >
            <el-button type="primary">导出EXCEL</el-button>
          </download-excel>
        </el-col>
      </el-row>

      <div class="content">
        <!-- 表格部分 -->
        <el-table
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.35)"
          @selection-change="handleSelectionChange"
          :data="tableData"
          height="100%"
          border
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            label="编号"
            prop="coding"
            align="center"
            width="120"
            fixed
          ></el-table-column>
          <el-table-column
            label="IMEI"
            align="center"
            prop="deviceId"
            width="180"
            fixed
          ></el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            align="center"
            width="200"
            fixed
          ></el-table-column>
          <el-table-column
            prop="status"
            label="当前状态"
            align="center"
            width="130"
            fixed
          >
            <template slot-scope="scope">
              <div :style="scope.row.status == '失联' ? 'color : red' : ''">
                {{ scope.row.status }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="130" v-for="item,i in timeList" :key="i" :label=item align="center">
            <template slot-scope="scope">
              <div>
                {{ scope.row.valueList[i] }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
  
  <script>
// 获取公司id及名称
import companySystem from "@/api/managementApi/CompanyInfo";
// 获取表格数据
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";

export default {
  components: {},
  props: {},
  data() {
    return {
      // 加载页面的展示
      loading: false,
      //页面默认选择时间
      timePickerValue: [],
      // 表格双向绑定数据
      tableData: [],
      excelData:[],
      timeList:[],
      // 导出文件绑定字段
      electricExcelfields: {},
    };
  },
  created() {
    // 设置默认时间
    this.setQueryData();
    // 获取表格数据
    this.getTableData();
  },

  methods: {
    // 获取表格数据（假数据）
    getTableData() {
      for (let i = 1; i < 45; i++) {
        let statusCode = Math.random() > 0.2 ? "正常" : "失联";
        console.log(statusCode);
        let obj = {
          deviceId: `1356790${i}`,
          coding: `a_${i}`,
          status: statusCode,
          deviceName: `虚拟设备${i}`,
          timeList:[],
          valueList:[]
        };
        for (let j = 10; j < 30; j++) {
          obj.timeList.push(`2020-03-${j}`)
          obj.valueList.push(Math.random().toFixed(2))
        }
        this.tableData.push(obj);
        this.timeList = this.tableData[0].timeList
        console.log(this.tableData);
      }
    },
    // 复选框选中添加用电量统计
    handleSelectionChange(val) {
      console.log(val);
      this.totalElectricUse = 0;
      for (let i = 0; i < val.length; i++) {
        this.totalElectricUse += Number(val[i].periodDifference);
      }
    },
    // 设置默认显示日期的方法
    setQueryData() {
      // 格式化方法放到最后
      this.timePickerValue[0] = this.$moment()
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.timePickerValue[1] = this.$moment().format("YYYY-MM-DD");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
// 导入表格css
@import "../../../assets/css/tableCss.scss";
// 导入led样式字体
@font-face {
  font-family: led regular;
  src: url("../../../assets/font/ds_digital/DS-DIGI.TTF");
}

.container {
  position: relative;
  box-sizing: border-box;
  padding: 90px 10px 0 10px;
  height: 100%;
  .content {
    height: 90%;
    padding: 10px 0;
  }
  .total {
    padding: 0 10px;
    position: absolute;
    bottom: 0;
    height: 60px;
    color: aqua;
    line-height: 60px;
    font-size: 20px;
    width: 95%;
    span {
      font-family: led regular;
      float: right;
      font-size: 40px;
    }
  }
}
</style>